<template>
  <!-- 新增售价盘编辑页面 -->
  <div class="page">
    <el-form
      :model="ruleForm"
      ref="ruleForm"
      label-width="100px"
      :rules="rules"
    >
      <el-form-item label="数字通证" prop="tokenName">
        <el-select v-model="ruleForm.tokenName" disabled> </el-select>
      </el-form-item>
      <el-form-item label="时间配置" prop="time">
        <el-input v-model="ruleForm.time" size=""></el-input>
      </el-form-item>
      <!-- 按钮 -->
      <el-form-item label="">
        <el-button type="info" @click="getBack">返回</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')"
          >修改</el-button
        >
        <el-button @click="resetForm('ruleForm')">重置</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ruleForm: {
        tokenName: "",
        time: "",
      },
      // 验证规则
      rules: {
        tokenName: [
          { required: true, message: "请输入分区类别", trigger: "blur" },
        ],
        time: [{ required: true, message: "请输入时间配置", trigger: "blur" }],
      },
      id: null,
    };
  },
  created() {
    this.id = this.$route.query.id;
    // 获取数据
    this.axios.get("/admin/closing/findById", { id: this.id }).then((res) => {
      console.log(res);
      if (res.data.code == 200) {
        this.ruleForm = res.data.data;
      }
    });
  },
  methods: {
    // 点击新增
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.axios
            .get("/admin/closing/updateTime", {
              id: this.ruleForm.id,
              time: this.ruleForm.time,
            })
            .then((res) => {
              if (res.data.code == 200) {
                this.$message({
                  type: "success",
                  message: "更新成功!",
                });
              }
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 重置
    resetForm(formName) {
      // this.$refs[formName].resetFields();
      this.ruleForm.time = "";
    },

    // 返回
    getBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  padding: 30px;
  width: 400px;
}
</style>
